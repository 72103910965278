import React from "react"
import ReactGA from 'react-ga';

// Google Analytics
if (process.env.BLUEPRINT_ANALYTICS_ID) {
  ReactGA.initialize(process.env.BLUEPRINT_ANALYTICS_ID);
}

export const wrapRootElement = ({ element }) => {
  return (
    <div>{element}</div>
  )
}