// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-page-js": () => import("./../../../src/pages/about/AboutPage.js" /* webpackChunkName: "component---src-pages-about-about-page-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-contact-page-js": () => import("./../../../src/pages/contact/ContactPage.js" /* webpackChunkName: "component---src-pages-contact-contact-page-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loading-js": () => import("./../../../src/pages/loading.js" /* webpackChunkName: "component---src-pages-loading-js" */),
  "component---src-pages-process-index-js": () => import("./../../../src/pages/process/index.js" /* webpackChunkName: "component---src-pages-process-index-js" */),
  "component---src-pages-process-process-page-js": () => import("./../../../src/pages/process/ProcessPage.js" /* webpackChunkName: "component---src-pages-process-process-page-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-services-page-js": () => import("./../../../src/pages/services/ServicesPage.js" /* webpackChunkName: "component---src-pages-services-services-page-js" */),
  "component---src-pages-success-index-js": () => import("./../../../src/pages/success/index.js" /* webpackChunkName: "component---src-pages-success-index-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-sections-portfolio-portfolio-detail-js": () => import("./../../../src/sections/portfolio/PortfolioDetail.js" /* webpackChunkName: "component---src-sections-portfolio-portfolio-detail-js" */)
}

